import { device } from "src/styles/breakpoints"
import * as oldColors from 'src/styles/colors'
import * as newColors from 'src/styles/newColors'
import styled from "styled-components"

export const ContentWrapper = styled.section`
  background-color: ${newColors.sand};
  padding: 24px;
  display: flex;
  justify-content: center;

  @media ${device.tablet}{
    padding: 64px 44px;
  }

  @media ${device.desktopLG}{
    padding: 96px 44px
  }
  
  .row, .container{
    padding: 0;
    margin: 0;
  }

  .content_wrapper{

    &__form{
      background-color: white;
      padding: 24px;
      border-radius: 14px;

      @media ${device.tablet}{
        border-radius: 28px;
      }

      @media ${device.desktopLG}{
        padding: 48px 42px;
      }

      @media ${device.desktopXXL}{
        background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/newsroom-1440/image.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
      }

      &__image{
        display: flex;
        margin: 0 auto;
        @media ${device.tablet}{
          width: 100%;
          margin: 0 0 0 12px
        }

        @media ${device.desktopLG}{
          width: 65%;
          margin-left: 16px;
        }

        &__source{
          @media ${device.tablet}{
            width: 100%;
            height: fit-content;
          }
        }
      }
      &__title{
        font-family: Citrina, sans-serif;
        font-weight: 500;
        color: ${newColors.primary[500]};

        font-size: 28px;
        line-height: 33.6px;
        margin: 24px 0 16px 0;

        @media ${device.tablet}{
          font-size: 40px;
          line-height: 44px;
          margin-top: 0;
        }
      }
      &__description{
        font-family: Inter, sans-serif;
        font-weight: 400;
        color: ${newColors.primary[500]};
        
        font-size: 14px;
        line-height: 16.94px;
        margin-bottom: 24px;
        letter-spacing: 0;
        
        @media ${device.tablet}{
          font-size: 16px;
          line-height: 19.36px;
        }

        @media ${device.desktopLG}{
          font-size: 18px;
          line-height: 21.8px;
        }

        @media ${device.desktopXXL}{
          width: 50%;
        }
      }
      &__fields{
        @media ${device.tablet}{
          margin-right: 12px;
          width: 100%;
        }

        @media ${device.desktopLG}{
          margin-right: 16px;
        }

        @media ${device.desktopXXL}{
          width: 50%;
        }

        &__input{
          width: 100%;
          margin-bottom: 4px;

          label{
            font-family: Inter, sans-serif;
            font-weight: 600;
            color: ${oldColors.grayscale[400]};
            font-size: 14px;
            line-height: 17px;
  
            @media ${device.desktopLG}{
              font-size: 16px;
              line-height: 19.36px;
            }
            
          }

        }
        &__labels{
          font-family: Inter, sans-serif;
          font-weight: 600;
          color: ${oldColors.grayscale[400]};
          font-size: 14px;
          line-height: 17px;
          margin: 0;

          @media ${device.desktopLG}{
            font-size: 16px;
            line-height: 19.36px;
          }

          &--content{
            margin-top: 24px;
          }
        }
        &__content{
          margin: 0 0 24px 0;
        }
        &__accept{
          display: flex;
          line-height: 12px;
          font-size: 10px;

          &__checkbox{
            margin: 0;
          }
          &__text{
            font-family: Inter, sans-serif;
            font-weight: 400;
            color: ${oldColors.grayscale[500]};
            font-size: 14px;
            line-height: 16.94px;

            display: flex;
            align-items: center;
            margin: 0;

            @media ${device.desktopLG}{
              font-size: 16px;
              line-height: 19.36px;
            }

            &--light{
              font-family: Inter, sans-serif;
              font-weight: 400;
              color: ${oldColors.grayscale[400]};
              font-size: 12px;
              letter-spacing: 0;
              
              display: contents;
              align-items: center;
              margin: 0;
            }
          }
          &--terms{
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
            @media ${device.desktopLG}{
              font-size: 12px;
              line-height: 14.52px;
            }
          }
        }

        &__submit{
          display: flex;
          width: 68%;
          margin: 0 auto;

          @media ${device.desktopXXL}{
            width: 50%;
          }
        }
      }
    }

    &__form_response{
      width: 100%;
      border-radius: 14px;
      background-color: white;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px;
      
      @media ${device.tablet}{
        border-radius: 28px;
      }

      @media ${device.desktopLG}{
      }

      @media ${device.desktopXXL}{
        padding: 50px;
      }

      &__close{
        margin-left: auto;
        width: 28px;
        width: 28px;

        margin-bottom: 64px;

        @media ${device.desktopLG}{
          width: 36px;
          width: 36px;
        }
      }
      &__icon{
        margin-bottom: 32px;
      }
      &__title{
        margin-bottom: 32px;

      }
      &__description{
        margin-bottom: 64px;
      }
    }
  }
`
